@import '../assets/styles/common.scss';

.cms-loading {
  &-indicator {
    position: relative;
    background: no-repeat center center;
    background-size: 90%;
    width: 110px;
    height: 110px;
  }
}

.cms-viewer {
  overflow: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .cms-module-wrap {
    flex: 1;
    width: 100%;
    max-width: $module-max-width;
    background-color: transparent;
    display: flex;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    overflow: hidden;

    &.is-full {
      max-width: 100%;
    }

    &:nth-child(1) {
      .module-listener {
        > div:not(.is-full-screen-module) {
          padding-top: 30px;
        }
      }
    }
    &:last-child {
      .module-listener {
        > div:not(.is-full-screen-module) {
          padding-bottom: 30px;
        }
      }
    }

    &.is-edit {
      &:hover {
        .edit-mask {
          display: flex;
        }
      }
    }

    .edit-mask {
      display: none;
      // border-radius: 8px;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
      align-items: center;
      justify-content: center;
      user-select: none;

      .next-btn:nth-child(1) {
        margin-right: $gutter-12;
      }
    }

    &.active {
      border: 2px dashed #00c4c0;
      background-color: rgba(47, 84, 235, 0.29);
      &:nth-child(1) {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      &:nth-last-child(1) {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
    &.hidden {
      opacity: 0.5;
      filter: blur(0.5px);
    }
  }

  .viewer-navigation-absolute {
    position: absolute;
    top: 200px;
    right: 0px;
  }

  .preview-navigation-fixed {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(610px) translateY(-50%);
  }
}
