.navigation-styles {
  width: 125px;
  z-index: 1001;
  border: 0px solid black;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;

  .quick-nav-content {
    background-color: #ffffff;
    text-align: center;
    border-radius: 0 0 8px 8px;
    padding: 5px 0 15px;
  }
  .quick-nav-title {
    background: #155acc;
    color: #ffffff;
    text-align: center;
    width: 125px;
    height: 29px;
    padding: 6px 0;
    border-radius: 8px 8px 0 0;
  }
  .quick-nav-box {
    vertical-align: middle;
    cursor: pointer;
    padding: 4px 0;
  }
  .quick-nav-box:hover {
    background-color: #e4f1fe;
  }
}
