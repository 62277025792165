$space-width: 48px;
$space-width-mb: 24px;
$space-height: 10px;
$font-color-title: #111111;
$font-color-default: #2e3346;
$font-color-gray: #595f6d;
$main-color: #1a71ff;
$border-color: #eee;
$background-color: #fff;
$gutter-12: 12px;
$error-color: #e64a4a;

/* 强调色 */
$accent-color-blue: #1a71ff;
$accent-color-pink: #ff3399;
$accent-color-purple: #aa3cff;
$accent-color-red: #c9060c;

/* 主题颜色 */
$theme-color-neutral: #2e3346;
$theme-color-blue: #2e3346;
$theme-color-pink: #8c1d5c;
$theme-color-purple: #5d2299;
$theme-color-red: #7a0408;

$tertiary-text-color: #858b9c;
$background-card-table: #f5f8fd;
$text-color-595f6d: #595f6d;

$text-primary-size: 16px;
$text-primary-weight-400: 400;
$text-primary-weight-500: 500;
$text-primary-weight-700: 700;
$disabled-text-color: #c8ccd7;

$font-family-default: Roboto, 'Helvetica Neue', Helvetica, Tahoma, Arial, 'PingFang SC', 'Microsoft YaHei';
$font-size-default: 14px;

$module-max-width: 1200px;

@mixin clamp-lines($num: 3) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $num;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cms-viewer,
.cms-editor {
  color: #2e3346;
  line-height: 1.2857142;

  .next-form-item {
    .next-input + .next-input {
      margin-top: 10px;
    }

    .next-upload + .next-input {
      margin-top: 10px;
    }
  }

  .next-btn.next-btn-normal:not(.next-btn-warning) {
    color: $font-color-default;
  }

  .next-input input,
  .next-input textarea {
    color: $font-color-default;
  }
}

.form-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;

  span.form-item-title {
    color: $font-color-default;
    font-size: 16px;
    font-weight: 700;
  }
}

.pre-content {
  word-wrap: break-word;
}

.pre-title {
  word-wrap: break-word;
  @include clamp-lines(3);
}

/* small 滚动条 */
.small-scrollbar::-webkit-scrollbar-track-piece {
  border-radius: 0;
}

.small-scrollbar::-webkit-scrollbar {
  width: 2px !important;
  height: 2px;
  border-radius: 1px;
}

.small-scrollbar::-webkit-scrollbar:hover {
  background-color: rgb(204, 204, 204);
}

.small-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(87, 92, 96, 0.2);
  border-radius: 5px;
  min-height: 60px;
}